<template>
  <div class="empresa">
    <vx-card v-if="empresa" title="Información empresarial" class="mb-base">
      <!-- Avatar -->
      <div class="vx-row">
        <!-- Avatar Col -->
        <div class="vx-col" id="avatar-col">
          <div class="img-container mb-4">
            <img :src="empresa.imagen" class="rounded w-full" />
          </div>
        </div>

        <!-- Information - Col 1 -->
        <div class="vx-col flex-1" id="account-info-col-1">
          <table>
            <tr>
              <td class="font-bold">Código:</td>
              <td>{{ activeUserInfo.company.code }}</td>
            </tr>
            <tr>
              <td class="font-bold">Nombre:</td>
              <td>{{ activeUserInfo.company.name }}</td>
            </tr>
            <tr>
              <td class="font-bold">NIT:</td>
              <td>{{ activeUserInfo.company.nit }}</td>
            </tr>
          </table>
        </div>
        <!-- /Information - Col 1 -->

        <!-- Information - Col 2 -->
        <div class="vx-col flex-1" id="account-info-col-2">
          <table>
            <tr>
              <td class="font-bold">Dirección:</td>
              <td>{{ activeUserInfo.company.address }}</td>
            </tr>
            <tr>
              <td class="font-bold">Teléfono principal:</td>
              <td>{{ activeUserInfo.company.phone_first }}</td>
            </tr>
            <tr>
              <td class="font-bold">Teléfono secundario:</td>
              <td>{{ activeUserInfo.company.phone_secondary }}</td>
            </tr>
          </table>
        </div>

        <!-- /Information - Col 2 -->

        <!-- Information - Col 3 -->
        <div class="vx-col flex-1" id="account-info-col-3">
          <table>
            <tr>
              <td class="font-bold">Email:</td>
              <td>{{ activeUserInfo.company.email }}</td>
            </tr>
            <tr>
              <td class="font-bold">Ciudad:</td>
              <td>
                {{
                  activeUserInfo.company.city
                    ? activeUserInfo.company.city.name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td class="font-bold">Representante legal:</td>
              <td>{{ activeUserInfo.company.user_legal }}</td>
            </tr>
          </table>
        </div>
        <!-- /Information - Col 3 -->
        <div class="vx-col w-full flex">
          <vs-button
            :to="{
              name: 'configuracion-empresa-editar-empresa',
            }"
            icon-pack="feather"
            icon="icon-edit"
            class="mr-4"
            >Editar</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empresa: null,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created() {
    // this.$http.get('/api/empresa').then(({ data }) => {
    //   this.empresa = data;
    // });

    this.empresa = {
      ...this.activeUserInfo.company,
      imagen: 'https://ui-avatars.com/api/?size=128',
    };
  },
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

.empresa {
  table {
    td {
      vertical-align: top;
      min-width: 150px;
      padding-bottom: 0.8rem;
      word-break: break-all;
      &:nth-child(2) {
        @apply text-slate-gray;
      }
      @screen md {
        min-width: 170px;
      }
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 425px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}
</style>
